/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 10, 2022 */



@font-face {
    font-family: 'alphiisemibold';
    src: url('alphii_semibold-webfont.woff2') format('woff2'),
         url('alphii_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlow_condensedsemibold';
    src: url('barlowcondensed-semibold-webfont.woff2') format('woff2'),
         url('barlowcondensed-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}