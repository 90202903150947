.SettingsPopup {
  position: absolute;
  top: 122px;
  left: 0;
  right: 0;
  bottom: 158px;

  width: 100%;
  max-width: 720px;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  background: linear-gradient(-45deg,#2F2F72FF ,#27509AFF );
}

.SettingsPopup .SettingsPopup-close{
  position: absolute;
  top: 20px;
  right: 20px;
}
.SettingsPopup .SettingsPopup-close .Button button {
  padding: 15px;
  width: 60px;
}

.SettingsPopup h2{
  font-family: "alphiisemibold", sans-serif;
  color: #73EFFFFF;
  font-size: 50px;
  text-align: center;
  margin-top: 40px;
}

.SettingsPopup .SettingsPopup-content {
  /* border: 1px solid red; */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

}


.SettingsPopup .Settings-text {
  width: 70%;
  margin: 0 auto;
  font-size: 25px;
  color: #fff;

}
.SettingsPopup .Settings-text em{
  color: #FFEC7D;
  font-style: normal;
}
.SettingsPopup .Settings-block {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  background: #31589A;
  text-align: center;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.SettingsPopup .Settings-block h3{
  font-size: 30px;
  color: #FFF;
  font-family: "alphiisemibold", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}
/* .SettingsPopup .SettingsPopup-option {
  display: flex;
  align-items: center;
  height: 45px;
  color: #FFF;
} */

.SettingsPopup .select-ctnr{
  /* border: 1px solid red; */
  max-width: 100%;

  position: relative;

}
.SettingsPopup .select-ctnr .Button{
  position: absolute;
  right: 0;
  height: 100%;
  aspect-ratio: 1/1;
  pointer-events: none;
}
.SettingsPopup .select-ctnr .Button button{
  padding: 0;
  /* height: 100%; */
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  justify-content: center;


}
.SettingsPopup .select-ctnr select{
  cursor: pointer;
  font-size: 30px;
  max-width: 100%;
  font-family: "alphiisemibold", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 999px;
  padding: 20px;
  padding-right: 100px;
}
.SettingsPopup .select-ctnr select option{
  font-size: 20px;
}


.Switch input{
  display: none;
}


.Switch span{
  cursor: pointer;
  display: inline-block;
  border-radius: 999px;
  border: 5px solid  #FFF;
  width: 115px;
  /* height: 100%; */

  background: linear-gradient(-45deg, #1A2F5C,#1D224B );
  padding: 10px;
  line-height: 0;
  user-select: none;

  color: var(--text-color);

  display: flex;
  align-items: center;
  justify-content: flex-start;

}


.Switch.checked span {
  justify-content: flex-end;
  border-color: #23E5B6;
}
.Switch.checked span::before {
  background: #23E5B6;

}


.Switch span::before{
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background: #FFF;
  background: linear-gradient(-45deg, #1A3565FF,#23488AFF );
  box-shadow: 5px 5px 0 0px #1E1E47FF;
  border-radius: 50%;
}