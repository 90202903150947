.Button {
  background: #FFF;

  font-size: 40px;

  border-radius: 999px;
  display: inline-block;
  box-shadow: 0 0 10px #2d1178;
  overflow: hidden;
  position: relative;
  z-index: 1;
  cursor: pointer;


  /* ios safari déconne avec les overflow au premier rendering... ceci améliore parfois les choses... */
  -webkit-transform-style: preserve-3d;
  will-change: transform;
  transform: translate3d(0, 0, 0.1);
}

.Button button{
  position: relative;
  z-index: 1;
  border-radius: 999px;

  display: block;
  font-size: 1em;
  color: #FFF;
  background: linear-gradient(-45deg, #2F2F72FF, #224887FF);
  margin: 10px;
  padding: 20px 40px;
  border: none;
  outline: none;

  cursor: pointer;

  display: flex;
  align-items: center;

  font-family: "alphiisemibold", sans-serif;
  /* box-shadow: 10px 10px 0px 2px #AAA6F4; */

  box-shadow: 1px 1px 0px #AAA6F4,
  2px 2px 0px #AAA6F4,
  3px 3px 0px #AAA6F4,
  4px 4px 0px #AAA6F4,
  5px 5px 0px #AAA6F4,
  6px 6px 0px #AAA6F4,
  7px 7px 0px #AAA6F4,
  8px 8px 0px #AAA6F4,
  9px 9px 0px #AAA6F4,
  10px 10px 0px #AAA6F4,
  11px 11px 0px #AAA6F4,
  12px 12px 0px #AAA6F4,
  13px 13px 0px #AAA6F4,
  14px 14px 0px #AAA6F4,
  15px 15px 0px #AAA6F4,
  16px 16px 0px #AAA6F4,
  17px 17px 0px #AAA6F4,
  18px 18px 0px #AAA6F4;

}

.Button button:hover{
  background: linear-gradient(-45deg, rgb(64, 64, 158), rgb(50, 98, 175));
}
