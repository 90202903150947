.ScreenGame  {
  width: 0;
  height: 0;
  opacity: 0;
}
.ScreenGame iframe {
  width: 100%;
  flex-grow: 1;
  border: none;
  outline: none;
}

.ScreenGame #game-content {
  width: 100%;
  height: 1000px;
  flex-grow: 1;
  border: none;
  outline: none;
}

.ScreenGame.visible {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  opacity: 1;


  display: flex;
  flex-direction: column;
}