.JitsiFooter {

  --green: rgb(54, 241, 163);;
  --red: rgb(236, 42, 133);



  width: 100%;
  height: 158px;

  background: #191919;
  color: #FFF;
  padding: 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  position: relative;

}

.JitsiFooter .mode_malentendant_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: url("../../../images/mode_malentendant_icon.png") rgba(0, 0, 0, 0.8) no-repeat center center;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #FFF;
  font-weight: bold;

  z-index: 10;


}

.JitsiFooter .Button {
  min-width: 130px;
  max-width: 130px;
  min-height: 130px;
  max-height: 130px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  /* padding: 15px; */
}

.JitsiFooter .Button button{
  display: block;
  /* width: 100%; */
  height: calc(100% - 20px);
  /* height: 100%; */
  /* height: auto; */
  padding: 0;
  padding: 25px;

  position: relative;
}

.JitsiFooter .Button button.muted::after{
  content: "";

  width: 100%;
  height: 15px;
  background: red;

  position: absolute;
  left: 0;
  top: 50%;



  transform: center center;
  transform:  translateY(-50%) rotate(45deg);
}

.JitsiFooter .jitsi-users {
  /* border: 1px solid red; */
  flex-grow: 1;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(3,1fr);  /* 3 columns */
  grid-template-rows: repeat(2,1fr); /* 3 rows  */
  grid-auto-flow: column;

  gap: 15px;
}


.JitsiFooter .jitsi-users .empty-user {
  width: 100%;
  height: 100%;
  background: #3f3f3f;
  border-radius: 999px;
}







.JitsiUser {
  font-size: 22px;
  font-weight: bold;

  min-width: 168px;
  min-height: 50px;
  max-height: 50px;

  border: 3px solid #191919;
  border-radius: 99px;
  -webkit-border-radius: 99px;

  display: flex;
  align-items: center;

  padding-left: 50px;
  padding-right: 20px;

  text-align: center;


  color: #000;
  background: white;


  position: relative;
}

.JitsiUser.me {
  grid-column: 1;
  grid-row: 2;

  border-radius: 0 99px 99px 0;
  padding-left: 80px;
  margin-left: -80px;
}
.JitsiUser:nth-child(2) {
  grid-column: 1;
  grid-row: 1;
}

.JitsiUser.highlighted {
  box-shadow: 0 0 0px 3px #0DFF63;
}




.JitsiUser .mini-mic-icon{
  position: absolute;
  left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: url('../../../images/mini-mic.png') #FFF no-repeat center center;
  background-size: 16px;

}
.JitsiUser .mini-mic-icon.muted{
  border: 3px solid #313173;
}
.JitsiUser .mini-mic-icon.muted:after{
  content: "";
  width: 100%;
  height: 5px;
  background: #313173;

  position: absolute;
  left: 0;
  top: 50%;



  transform: center center;
  transform:  translateY(-50%) rotate(45deg);

}


.JitsiUser .JitsiUser-pseudo{

  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: center;
  width: 100%;
}

.JitsiUser.absent {
  border: 3px dashed #FFF;
  background: transparent!important;
  opacity: 0.5;
}

.JitsiUser.absent .mini-mic-icon{
  filter: invert();
  border: 2px solid #000;
}

.JitsiUser-pseudo-ctnr {
  /* border: 1px solid red; */
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.JitsiUser .JitsiUser-checkmark {

  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%)  translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #191919 no-repeat center center;
  background-size: 16px;
  border: 3px solid #0DFF63;

  display: flex;
  justify-content: center;
  align-items: center;
}


.JitsiUser .JitsiUser-checkmark::after {
  content: "";

  width: 6px;
  height: 11px;
  border: 3px solid;
  border-color: inherit;
  border-top: none;
  border-left: none;

  transform:translateY(-2px) rotate(45deg) ;

}

.JitsiUser.absent .JitsiUser-checkmark {
  display: none;
}


.jitsi-permission-error{
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  text-align: center;
  color: #FFF;

}