.ModeMalentendantPopup-overlay {
  position: absolute;
  z-index: 60;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
.JistiModeMalentendantPopup em{
  font-style: normal;
  color: #2CFFBEFF;

}