.GameHeader {
  /* position: fixed; */
  /* top: 0; */
  background: #191919;
  padding: 0 20px;
  width: 100%;
  height: 122px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px ;
}
.GameHeader .spacer{ flex-grow: 1; }

.GameHeader .global-timer{
  color: #73EFFF;
  background: url('../../../images/timer-icon.png') left center no-repeat;
  background-size: contain;

  padding-left: 80px;
  font-size: 25px;
}
.GameHeader .global-timer-time{
  font-size: 35px;
  font-family: "alphiisemibold", sans-serif;
}

.GameHeader .Button button {
  padding: 10px 20px;
}

.GameHeader .Button button img{
  height: 35px;
}