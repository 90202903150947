.ScreenEndGame {
  height: 100%;
  display: flex;

  flex-direction: column;
}
.ScreenEndGame .ScreenEndGame-content{
  flex-grow: 1;
  background: url('../../images/fin-bg.png') no-repeat center center;
  background-size: cover;
  display: flex;

  flex-direction: column;
  justify-content: space-around;
}
.ScreenEndGame .logo{
  text-align: center;
}
.ScreenEndGame .logo img{
  width: 300px;
}
.ScreenEndGame .ScreenEndGame-content h1{
  font-size: 120px;
  color: #F4885D;

  font-family: "alphiisemibold", sans-serif;
  text-align: center;

}
.ScreenEndGame.end_good .ScreenEndGame-content h1{ color: #2CFFBE;}

.ScreenEndGame .ScreenEndGame-content h2{
  color: #FFF;
  text-transform: uppercase;
  font-size: 40px;
  text-align: center;
  font-weight: normal;
}
.ScreenEndGame .ScreenEndGame-content .ScreenEndGame-text{
  color: #FFF;

  font-size: 30px;
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
}


.ScreenEndGame .ScreenEndGame-content .Button{
  margin: 0 auto;
}

.ScreenEndGame .Button button.ghost {
  background: #0F0F23;
  box-shadow: none;
  margin: 5px;
}
.ScreenEndGame .ScreenEndGame-content .timer{

  margin: 0 auto;

}


.ScreenEndGame .ScreenEndGame-content a{
  display: block;
  margin: 0 auto;
}

.ScreenEndGame .ScreenEndGame-content .timer .timer-title{
  background: linear-gradient(-45deg, #2F2F72FF, #27529CFF);
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
  padding: 15px ;
  font-size: 35px;
}
.ScreenEndGame .ScreenEndGame-content .timer .timer-time{
  background: linear-gradient(-45deg, #2F2F72FF, #27529CFF);
  font-size: 90px;
  padding: 5px 40px;
}