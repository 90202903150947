.ScreenHome {

  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: url('../../images/bg.jpg') no-repeat center center;
  background-size: cover;
  margin: 0 auto;

  padding: 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.ScreenHome h2 {
  color: #FFF;
  text-align: center;
  font-size: 33px;
}

.ScreenHome h1 {
  text-align: center;
}
.ScreenHome h1 img{ width: 50%; }

.ScreenHome .error.fullscreen-error {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ScreenHome .error {
  text-align: center;
  font-size: 35px;
  color: #FFEC7D;
  font-weight: bold;
  text-shadow: 0 0 10px #2d1178;
  margin: 0 20px;
}

.ScreenHome .info{
  padding: 20px;
  background-color: #ffec7d;
  color: #315c5a;
  font-weight: bold;
  font-size: 30px;
  border-radius: 999px;
  margin: 20px auto;
  max-width: 80%;
  box-shadow: 0 0 10px #2d1178;

  background: url("../../images/info-icon.png") #ffec7d 20px center no-repeat;
  background-size: 60px;
  padding-left: 100px;
  padding-right: 40px;
}
.ScreenHome .microphone-info{
  background: url("../../images/microphone-icon.png") #ffec7d 20px center no-repeat;
  background-size: 70px;
  padding-right: 40px;


  border-radius: 999px 0 0 999px;


  width: 60%;
  margin: 0;
  align-self: flex-end;

  transform: translateX(30px) translateY(-10px) rotate(-5deg);
}

.ScreenHome .meeting-form {
  text-align: center;
  margin: 30px 0;
}
.ScreenHome .meeting-form input {
  display: block;
  font-size: 60px;
  width: 70%;
  margin: 0 auto;
  padding: 40px 30px;
  border-radius: 15px;
  border: none;
  font-weight: bold;

  text-align: center;
}
.ScreenHome .meeting-form .Button {
  transform:   translateY(-10px);
  font-size: 55px;
}



.ScreenHome .no-code { text-align: center; }
.ScreenHome .no-code p {
  color: #FFF;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 50px;
}

.ScreenHome .no-code { text-align: center; }
.ScreenHome .step2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ScreenHome .no-code-2 { text-align: center; visibility: hidden; }
.ScreenHome .no-code-2 p {
  color: #FFF;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 50px;
}