* {box-sizing: border-box;}

:root {
  --main-color: #CEFACD;
}
/* @import '../../fonts/fontawesome/css/all.min.css'; */
@import '../../fonts/stylesheet.css';

* {
  font-family: "barlow_condensedsemibold", sans-serif;
}
html, body, #root, .App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  user-select: none;
}

body {
  background: url("../../images/app_bg.jpg" ) #07253F center center;
  background-size: cover;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin: 0 auto;

  position: relative;
}


.App2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}
.version {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 0.8em;
}
.debug {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 0.8em;
  color: #FFF;
  background: red;

}


.screen {
  flex-grow: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}
.screen .screen-content {
  flex-grow: 1;
}




button.link-btn {

  background: none;
  border: none;
  padding: 0;
  color: darkblue;
  cursor: pointer;
}
button.link-btn:hover {
  text-decoration: underline;
}


.error {
  color: darkred;
}


select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] { font-size: 16px; }